<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>市场应用</a-breadcrumb-item>
            <a-breadcrumb-item>云打印</a-breadcrumb-item>
            <a-breadcrumb-item>详情</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="huidongPrint-body">
            <div class="clearfix table-tools" style="padding-top:10px">
            <div class="buttons">
                <a-form layout='inline'>
                    <a-form-item>
                        <a-button v-if='!imgCheckable && canDelete' icon="delete" @click="toDeleteImages">批量删除</a-button>
                        <a-button v-if='imgCheckable' type="primary" @click="confirmDeleteImages">确认删除</a-button>
                        <a-button v-if='imgCheckable' style="margin-left:10px" @click="cancelDeleteImages">取消删除</a-button>
                    </a-form-item>
                    <a-form-item>
                        <a-button v-if="canExcelexport" @click="toExport" icon="export" :loading="exportLoading">导出</a-button>
                    </a-form-item>
                    <a-form-item>
                        <a-button @click="toBack">返回</a-button>
                    </a-form-item>
                </a-form>
            </div>
            <div class="search">
                <a-form layout='inline'>
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>所属校区</span>
                        </template>
                            <a-select v-model="searchParams.studio_id" showSearch allowClear placeholder="请选择校区" :filterOption="filterOption" style="width:210px">
                                <a-select-option v-for="(item, index) of studios" :key="index" :value="item.studio_id">{{ item.filter_name }}</a-select-option>
                            </a-select>
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                    <a-button type="primary" @click="searchList">搜索</a-button>
                    </a-form-item>
                </a-form>
            </div>
        </div>
            <div>
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <waterfall :col="6" :data="list" :height="wHeight" @loadmore="loadmore" :loadDistance="20">
                    <template>
                    <div
                        class="pr pbImgBox"
                        v-for="(item,index) in list"
                        :key="index"
                    >
                        <a-card hoverable style="width: 220px;margin-bottom:15px;position: relative;">
                            <img
                            slot="cover"
                            alt="example"
                            :preview="1"
                            :src='item.print_pic'
                            />
                            <a-card-meta :title="item.student_name" description="">
                                <a-avatar
                                    slot="avatar"
                                    :src="item.student_avatar"
                                />
                            </a-card-meta>
                            <a-checkbox v-if='imgCheckable' style="position: absolute;top: 10px;right: 10px;" @change="e=>onChange(e,item)"></a-checkbox>
                        </a-card>
                    </div>
                    </template>
                </waterfall>
            </div>
            <LEmpty v-if="list.length == 0"/>
        </div>
    </div>
</template>

<script>
import JSZip from'jszip'
import FileSaver from'file-saver'
import ranges from "@/common/mixins/ranges"
import authority from '@/common/mixins/authority'
    export default {
        name:'huidongPrint',
        data() {
            return {
                loading:false,
                busy: false,
                imgCheckable: false,
                exportLoading: false,
                myHeight:0,
                searchParams: {
                    "page": 1,
                    "per-page": 20,
                    "search": {},
                    "sort": ''
                },
                studios:[],
                albumList:[],
                list:[],
                deleteList:[],
                authType:['market','huidong_print'],
            }
        },
        mixins:[ ranges, authority ],
        computed:{
            wHeight(){
                return `${Number(this.myHeight) - 250}px`
            }
        },
        created () {
            this.myHeight = this.windowHeight()
            this.searchParams.print_year = this.$route.query.print_year
            this.searchParams.print_week = this.$route.query.print_week
            this.getList()
            this.getStudioList()
        },
        methods: {
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            async getList(){
                this.loading = true
                let res = await this.$store.dispatch('huidongPrintAction',this.searchParams)
                if(res.items.length >0){
                    this.list = [...this.list,...res.items]
                }
                if(res._meta.totalCount == this.list.length){
                    this.busy = true
                }
                this.loading = false
            },
            async getStudioList() {
                let res = await this.$store.dispatch('searchBelongStudioAction', { })
                this.studios = res.data
            },
            loadmore(){
                console.log('我加载了')
                if(!this.busy){
                    this.searchParams.page++
                    this.getList()
                }
            },
            windowHeight() { 
                var de = document.documentElement
                return self.innerHeight||(de && de.offsetHeight)||document.body.offsetHeight;
            },
            searchList(e) {
                e?e.preventDefault():''
                this.list = []
                this.searchParams.page = 1
                this.getList()
            },
            async toExport(){
                this.exportLoading = true
                await this.$store.dispatch('huidongPrintExportAction', {print_year:this.searchParams.print_year,print_week:this.searchParams.print_week,studio_id:searchParams.studio_id})
                .then(res=>{
                    this.BatchDownload(res.data,`${this.searchParams.print_year}-${this.searchParams.print_week}`)
                })
            },
            getImgArrayBuffer(url){
                let _this=this
                return new Promise((resolve, reject) => {
                    //通过请求获取文件blob格式
                    let xmlhttp = new XMLHttpRequest()
                    xmlhttp.open("GET", url, true)
                    xmlhttp.responseType = "blob"
                    xmlhttp.onload = function () {
                        if (this.status == 200) {
                            resolve(this.response)
                        } else {
                            reject(this.status)
                        }
                    }
                    xmlhttp.send()
                })
            },
            BatchDownload(imgDataUrl,name){
                let _this = this
                let zip = new JSZip()
                let cache = {}
                let promises = []
                _this.title = '正在加载压缩文件'
                for (let item of imgDataUrl) {
                const promise= _this.getImgArrayBuffer(item.print_pic).then(data => {
                    // 下载文件, 并存成ArrayBuffer对象(blob)
                    zip.file(item.print_pic_name, data, { binary: true }) // 逐个添加文件
                    cache[item.print_pic_name] = data
                });
                promises.push(promise)
                }

                Promise.all(promises).then(() => {
                zip.generateAsync({ type: "blob" }).then(content => {
                    _this.title = '正在压缩'
                    // 生成二进制流
                    FileSaver.saveAs(content, name) // 利用file-saver保存文件  自定义文件名
                    _this.title = '压缩完成'
                    _this.exportLoading = false
                });
                }).catch(res=>{
                _this.$message.error('文件压缩失败')
                _this.exportLoading = false
                })
            },
            toBack(){
                this.$router.go(-1)
            },
            onChange(e,item){
                if(e.target.checked){
                    this.deleteList.push(item.id)
                }else{
                    let index = this.deleteList.indexOf(item.id)
                    this.deleteList.splice(index,1)
                }
            },
            toDeleteImages(){
                this.imgCheckable = true
            },
            cancelDeleteImages(){
                this.imgCheckable = false
            },
            confirmDeleteImages(){
                let that = this
                this.$confirm({
                    title: `您确定删除这些照片？`,
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                    return new Promise(async (resolve, reject) => {
                        let res = await that.$store.dispatch('huidongPrintDeleteAction', {id:that.deleteList})
                        if (res) {
                            that.$message.success('删除成功！！')
                            that.list = []
                            that.searchParams.page = 1
                            that.busy = false
                            that.imgCheckable = false
                            that.deleteList = []
                            that.getList()
                            resolve(res)
                        }
                    }).catch(error => console.log(error))
                    }
                })
            }
        },
    }
</script>

<style lang="scss" scoped>
.huidongPrint-body{
    padding-left:20px;
    background: #fff;
    overflow: hidden;
}
.huidongPrint{
    display: flex;
    position: relative;
    .huidongPrint-box{
        flex: 1;
        overflow-y: scroll;
        display: flex;
        flex-wrap: wrap;
        .huidongPrint-item{
            margin-right: 30px;
        }
    }
}

</style>